
import { Options, Vue } from 'vue-class-component';

import Menu from "@/components/Menu.vue";

import Link from "@/types/Link";

@Options({
  components: {
    Menu,
  },
})
export default class App extends Vue {

  get links(): Array<Link> {
    return this.store.getters.links;
  }
}
